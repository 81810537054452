import { SEARCH_FIELDS_METADATA } from './searchFieldsMetadata';
import type { CompaniesConfig } from './type';

export * from './type';

export const COMPANIES_CONFIG: CompaniesConfig = {
    sellerPage: {
        provinceImageFile: {
            '1': 'dolnoslaskie',
            '2': 'kujawskoPomorskie',
            '3': 'lubelskie',
            '4': 'lubuskie',
            '5': 'lodzkie',
            '6': 'malopolskie',
            '7': 'mazowieckie',
            '8': 'opolskie',
            '9': 'podkarpackie',
            '10': 'podlaskie',
            '11': 'pomorskie',
            '12': 'slaskie',
            '13': 'swietokrzyskie',
            '14': 'warminskoMazurskie',
            '15': 'wielkopolskie',
            '16': 'zachodniopomorskie',
        },
        defaultBoundingBoxForMap: {
            neLat: 54.85131525968606,
            neLng: 24.14794921875,
            swLat: 48.96579381461063,
            swLng: 13.974609375,
        },
        isCitiesFactoidEnabled: true,
        hasAgentAccounts: true,
        searchFieldsMetadata: SEARCH_FIELDS_METADATA,
    },
};
